<template>
    <div>
        <ContextTitle
            :passedActions="titleBarActions" 
            title="Create Sublocation"
        />
        <span class="required-bar"></span>
        <span
            v-if="error"
            class="partner-form-error-bar"
        >
        {{ error }}
        </span>
        <SublocationForm
            :sublocation="sublocation"
            :allSublocations="partner.cursusPartnerSubLocations"
            :countryCode="partner.countryCode"
            :partnerShortCode="partner.partnerShortCode"
        />

    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import clone from 'clone'

import ContextTitle from '../../components/context_title.vue'
import SublocationForm from 'components/partners/sublocation_form.vue'

import { sublocationStruct } from 'helpers/partners.js'

import SublocationFormValidator from 'mixins/sublocation_form_validator.js'

export default {
    name: 'SublocationCreate',
    components: {
        ContextTitle,
        SublocationForm,
    },
    mixins: [
        SublocationFormValidator,
    ],
    data() {
        return {
            sublocation: sublocationStruct,
        }
    }, 
    computed: {
        titleBarActions() {
            const cancel = {
                type: 'cancel',
                display: 'Cancel',
                run: this.cancel,
            }

            const save = {
                type: 'proceed',
                display: 'Save',
                run: this.save,
            }
            
            return [cancel, save]
        },
        partner() {
            const partnerId = this.$route.params.partner
            
            if (this.partnerList === undefined) return undefined

            return this.partnerList.find(el => el.id == partnerId)
        },
        ...mapGetters('PartnerStore', [
            'partnerList',
        ])
    },
    methods: {
        save() {
            if (!this.validateForm()) return
            const cloned = clone(this.sublocation)
            cloned.partnerShortCode = this.$route.params.partner
            this.createSublocation(cloned).then(() => {
                this.$router.push({name: 'sublocations', params: {partner:`${this.partner.id}`}})
            })
        },
        cancel() {
            this.$router.push({name: 'sublocations', params: {partner:`${this.partner.id}`}})
        },
        ...mapActions('PartnerStore', [
            'createSublocation'
        ])
    },
}
</script>

<style lang="scss">
</style>
