export const partnerStruct = {
    addressLine1 : "",
    addressLine2 : "",
    commercialContact : "",
    commercialContactEmail : "",
    commercialContactPhone : "",
    commercialContactTitle: "",
    companyNumber : "",
    country : "",
    countryCode: "",
    cursusPartnerSubLocations: [],
    financialContact : "",
    financialContactEmail : "",
    financialContactPhone : "",
    financialContactTitle : "",
    partnerAssignment : "",
    partnerName : "",
    partnerShortCode : "",
    specialNotes : "",
    stateProvinceRegion : "",
    url : "",
    vat : "",
    city : "",
}

export const sublocationStruct = { 
    ccProcessingCap: '',
    commisionRate : "",
    commisionRateExpiration : "",
    connectivityFunding : "Yep",
    location : "",
    marketingBudget : "",
    partnerShortCode: "",
    posSystem : "",
    posSystemID : "",
    subLocationID : "",
    subLocationName : "",
    waypoints : []
}